var wipwrp = window.wipwrp || {};

var imageGeo = "../../images/servizi-areaGeog-web.png";
var imageProdEno = "../../images/servizi-prodEno-web.png";
var imageElenco = "../../images/servizi-elencoArtig-web.png";

wipwrp.afLive = (function ($) {
	var self = {};
	var step = 1;
	var _vars = {};
	var serviceImages = {
		1: imageGeo,
		2: imageProdEno,
		3: imageElenco,
	};
	self.init = function () {
		wipwrp.commons.copyGroup();
		self.initiateModal();
		smoothNavigate();
		addListenerServiceInbox();
		handleWatchVideoBtn();
		wipwrp.commons.initCkEditor('#storia_attivita');
		if (document.getElementById("aflive-steps-page")) {
			setSteps();
			_setVexExit();
		}
	};
	function preventDefault(fn) {
		return function (evt) {
			evt.preventDefault();
			if (typeof fn === "function") {
				return fn(evt);
			}
		};
	}

	function _setVexExit(){
    _vars.alertExitAdmission = document.getElementById('exit-alert');

		var menuBar = document.querySelector('#masthead>nav');
        // window.addEventListener('beforeunload',_beforeUnloadHandler);
        if (menuBar) {
            menuBar.addEventListener('click',function(e){
                // Fermo!
                e.preventDefault();
                //Controllo se target ha un href non vuoto e non è undefined ("fiera" e "piattaforma digitale" non hanno href)  
                if(e.target.href !=='' && e.target.href !== undefined ){
                    var href = e.target.href;
                    //apriamo l'alert di abbandono passandoli l'href per eseguire il flusso del link nel menu in caso di conferma
                    _openAbandonAlert(href);
					// se clicchiamo invece su "fiera" o "piattaforma digitale" passeremo lo slug invece dell'href
                } else {
					var slug = e.target.dataset.slug;
					_openAbandonAlert(slug);
				}
            });
		}
	}

	function _openAbandonAlert(goTo){
        if(!_vars.alertExitAdmission.classList.contains('open')){
            _vars.alertExitAdmission.classList.add('open');
		}

        var txtOkBtn = _vars.alertExitAdmission? _vars.alertExitAdmission.dataset.textOkBtn:'SI';
        var txtCancelBtn = _vars.alertExitAdmission? _vars.alertExitAdmission.dataset.textCancelBtn:'NO';
        vex.dialog.confirm({
            unsafeMessage:_vars.alertExitAdmission,
            showCloseButton:true,
            overlayClosesOnClick:false,
            buttons: [
                $.extend({},vex.dialog.buttons.YES,{
                    text: txtOkBtn,
                    className: 'btn-1 outline',
                }),
                $.extend({},vex.dialog.buttons.NO,{
                    text: txtCancelBtn,
                    className: 'btn-1 ',
                })
            ],
            callback: function(value){
                // Se clickato YES
                if(value){
                    window.removeEventListener('beforeunload',_beforeUnloadHandler);
                    window.location.href = goTo?goTo:'/';
                }
            }
        })
	}

	var _beforeUnloadHandler = function(e){
        /**
         NOTA: Dovuto a nuove specifiche nei browser moderni non possiamo implementare il popup alert
         tramite l'evento beforeunload (https://www.chromestatus.com/feature/5349061406228480)
         Di consequenza verrà visualizzato l'alert di conferma standard di ogni browser, se la
         pagina corrente (domanda) venisse ricaricata/cambiata.
         **/
        e.preventDefault();
        e.returnValue='';
    };

	function handleWatchVideoBtn() {
		$("#watchVideoBtn").on("click", function () {
			$.fancybox.open({
				href: "#videoFrame",
				minHeight: 600,
				maxWidth: 1200,
				padding: 25,
				tpl: {
					closeBtn:
						'<a title="Close" class="fancybox-item fancybox-close icon icon-cancel" href="javascript:;"></a>',
				},
			});
		});
	}

	function addListenerServiceInbox() {
		$(".service-list")
			.find(".list-item-checkbox")
			.each(function () {
				$(this).on("change", function (evt) {
					var totalPriceEl = $(".footer-price").find(".total-price");
					var servicePriceEl = $(this).parent().find(".big-price");
					var taxPercentage = $("input[name='tax_percentage']");
					taxPercentage = Number(taxPercentage.val());
					console.log('typeOf', typeof(taxPercentage))
					totalPrice = Number(totalPriceEl.html().replace(",", "."));
					servicePrice = Number(servicePriceEl.data("service-price").replace(",", "."));
					console.log('servicePriceEl.data("service-price")', servicePriceEl.data("service-price"))
					console.log('totalPrice', totalPrice);
					console.log('servicePrice', servicePrice);
					finalServicePrice = servicePrice;
					if (!!taxPercentage) {
						finalServicePrice = servicePrice + servicePrice * taxPercentage;
					}
					console.log('finalServicePrice', finalServicePrice);
					if (this.checked) {
						totalPrice = totalPrice + Number(finalServicePrice.toFixed(2));
					} else if (totalPrice > 0) {
						totalPrice = totalPrice - Number(finalServicePrice.toFixed(2));
					}
					totalPriceEl.text(totalPrice.toFixed(2).replace(".", ","));
				});
			});
	}

	function addListenerToOpenModal() {
		$(".info-button").each(function () {
			$(this).on("click", function (evt) {
				evt.preventDefault();
				var $parent = $(this).parent();
				var serviceId = $(this).parent().parent().find("input").val();
				console.log("serviceId", serviceId);
				openInfoModal();
				// $("span.list-item-title", $parent).data("image"),
				loadDataInfoModal(
					serviceImages[serviceId],
					$("span.list-item-title", $parent).html(),
					$("p.list-item-description", $parent).html()
				);
			});
		});
	}

	function closeInfoModal() {
		infoModal$.modal.style.display = "none";
	}

	function openInfoModal() {
		infoModal$.modal.style.display = "block";
	}

	function loadDataInfoModal(image, title, text) {
		pathImage = "http://placehold.it/1200x1920";
		if (image != "") {
			pathImage = image;
		}
		infoModal$.image.src = pathImage;
		infoModal$.title.textContent = title;
		infoModal$.text.textContent = text;
	}

	self.initiateModal = function () {
		infoModal$ = {
			modal: document.querySelector(".aflive-info-modal"),
			closeBtn: document.querySelector(".aflive-info-modal .modal__closebtn"),
			image: document.querySelector(
				".aflive-info-modal .modal-img_container img"
			),
			title: document.querySelector(".aflive-info-modal .content-title"),
			text: document.querySelector(".aflive-info-modal .content"),
		};
		if (infoModal$.modal) {
			infoModal$.modal.style.display = "none";
			infoModal$.closeBtn.addEventListener(
				"click",
				preventDefault(closeInfoModal)
			);
			addListenerToOpenModal();
		}
	};

	function smoothNavigate() {
		var anchors = document.querySelectorAll(".show-packages");
		anchors.forEach((anchor) => {
			anchor.addEventListener("click", function (e) {
				e.preventDefault();
				document.querySelector(this.getAttribute("href")).scrollIntoView({
					behavior: "smooth",
				});
			});
		});
	}

	function setSteps() {
		var _counter = "";
		var _index;
		window.location.hash = "#step1";
		step = 1;
		$(".panel.step").removeClass("current");
		$('.panel.step[data-step="' + step + '"]').addClass("current");

		_switchStepFunctions();
		window.onhashchange = hashChange;

		$(".panel.step").each(function (i) {
			_index = i + 1;
			if ($(this).hasClass("current")) {
				_counter += '<li class="active">' + _index + "</li>";
			} else {
				_counter += "<li>" + _index + "</li>";
			}
		});
		$(".steps_component ul").append(_counter);

		changeStep();
	}

	function hashChange() {
		$(".steps_component ul li.active")
			.removeClass("active")
			.addClass("visited");
		$(".panel.step.current").removeClass("current").addClass("visited");
		$(".steps_component ul li:eq(" + (step - 1) + ")").addClass("active");
		$(".panel.step:eq(" + (step - 1) + ")").addClass("current");
		$("html, body").animate({ scrollTop: 0 }, "slow");
		if (step > $(".panel.step").length) {
			$("#aflive-summary").addClass("hidden");
			$("#steps-notification").removeClass("hidden");
		}
		_switchStepFunctions();
	}

	function _validateStep(blockstep) {
		var parsleyValidation = false;
		if (
			$("form#form_aflive_steps")
				.parsley()
				.validate({ group: "step" + blockstep, force: false }) === true
		) {
			//check merceologie e nomi prodotto
			console.log("PARSLEY true")
			parsleyValidation = true;
		}

		return parsleyValidation;
	}

	var ppt = true;

	function changeStep() {
		$(".step-nav").on("click", function (e) {
			e.preventDefault();
			if (ppt === true) {
				ppt = false;
				// AVANTI
				if ($(this).hasClass("next")) {
					step = parseInt(step, 10);
					if (_validateStep(step)) {
						step += 1;
						window.location.hash = "#step" + step;
					}
					// TORNO AL RECAP (VISIBILE SOLO DOPO LA PRIMA VOLTA CHE ARRIVO ALLA FINE)
				} else {
					// INDIETRO
					step = parseInt(step, 10);
					if (step > 1) {
						// window.location = "/piattaforma_digitale";
						step -= 1;
						window.history.back();
					}
					// step -= 1;
					// window.history.back();
					// window.location.hash = "#step" + step;
				}
				setTimeout(function () {
					ppt = true;
				}, 1500);
			}
		});
	}

	// function _validateStep(step) {
	// 	var result = true;
	// 	var inputs;
	// 	var checkedInputs;
	// 	switch (step) {
	// 		case 1:
	// 		case 2:
	// 			// var checkedInputs = $('.panel.step[data-step=\"'+step+'\"] :input[type=radio]:checked');
	// 			// var inputs = $('.panel.step[data-step=\"'+step+'\"] :input[type=radio]');
	// 			var inputs = $(
	// 				'.panel.step[data-step="' + step + '"] :input[type=radio][value="1"]'
	// 			);
	// 			break;
	// 	}
	// 	inputs.each(function () {
	// 		$(this).closest(".action-inputs").removeClass("control-group error");
	// 		if (!$(this).is(":checked")) {
	// 			$(this)
	// 				.closest(".action-inputs")
	// 				.addClass("control-group")
	// 				.addClass("error");
	// 			result = false;
	// 		}
	// 	});
	// 	return result;
	// }

	function _switchStepFunctions() {
		var blockstep = parseInt($(".panel.step.current").data("step"), 10);
		switch (blockstep) {
				case 1:
          wipwrp.switcherTipoContratto.init();
          getCostiRappresentata();

					break;
				case 2:
            wipwrp.autocomplete.init({
                selector: "#nazionemagazzino",
                valueinfield: false
            });
            wipwrp.autocomplete.init({
                selector: "#prefissomagazzino",
                valueinfield: true
            });
            wipwrp.autocomplete.init({
                selector: "#provinciamagazzino",
                valueinfield: true
            });
            wipwrp.autocomplete.init({
                selector: "#provincia_azienda",
                valueinfield: true
            });
            wipwrp.autocomplete.init({
                selector: "#prefissotelefono_azienda",
                valueinfield: true
            });

			$('#nazionemagazzino_value').on('change', function (e) {
				var province$ = $('#provinciamagazzino, label[for="provinciamagazzino"]')
				if (e.target.value !== 'ITA') {
					province$.hide();
					province$.removeAttr('required')
				} else {
					province$.show();
					province$.attr('required', true)
				}
			});


					break;
				case 3:
					$("#steps-final-btn").click(function (e) {
						e.preventDefault();
						if(_validateStep(3)) {
							$("#form_aflive_steps").submit();
						}
					});
					wipwrp.autocomplete.init({
						selector: "#prefissotelefono_rappresentante",
						valueinfield: true
					});
					wipwrp.autocomplete.init({
						selector: "#prefissotelefono_responsabile_aflive",
						valueinfield: true
					});
					break;
		}
	}


	function getCostiRappresentata(){
      $('#save-step1').on('click', function(e){
          e.preventDefault();
          var contractType = $('input[name="TipoContratto"]:checked').val();
          if(parseInt(contractType)===2){
              $.ajax({
                  url: "/piattaforma_digitale/getForfaitRapp?json=1",
                  type: "POST",
                  dataType: "json",
                  data: {"rif": $('[name="Collettiva"]').val()},
                  success: function(data){
                      console.log(data);
                      var tax_perc= parseFloat($('input[name=tax_perc]').val());
                      var costoRapp= 0;
                      if(data.costoIsForfaitRappresentateAfLive==1){
                          costoRapp= parseFloat(data.costoTotaleForfaitRappresentateAfLive);
                      }
                      var tax= tax_perc * costoRapp;
                      var finalTotal= costoRapp + tax;

                      $('input[name=package_total]').val(costoRapp);
                      $('input[name=sub_total]').val(costoRapp);
                      $('input[name=tax]').val(tax);
                      $('#sub_price>strong').html('€ '+costoRapp.toFixed(2));
                      $('#tax_price>strong').html('€ '+tax.toFixed(2));
                      $('input[name=final_total]').val(finalTotal);

                      var finalInteger = Math.floor(finalTotal);
                      var finalDecimal = Math.round(parseFloat(finalTotal - finalInteger) * 100);

                      $('#final_price>strong').html('<span class="big-price">'+finalInteger+'</span>,'+(finalDecimal<=9?'0':'')+finalDecimal);
                      $('#aflive-summary').show();
                  },
                  error: function(error){
                      console.log("Error:");
                      console.log(error);
                      $('#aflive-summary').show();
                  }
              });
          }else{
              $('#aflive-summary').show();
          }
      });
	}

	return self;
})(jQuery);
